<template>
  <div
    :class="['sliding-wrapper', { hideArrows }]"
    ref="slidingWrapper"
    :style="wrapperStyle"
  >
    <button
      class="arrow --prev"
      v-show="
        !isStory && (numberOfVideosToDisplay < videos.length || isInPreview)
      "
      @click="prevSlide()"
    >
      <Arrow
        v-if="!hideArrows"
        :arrow-settings="formatCarouselObject.arrowSettings"
      ></Arrow>
    </button>
    <div
      class="vidjet-carousel-items"
      :class="[{ 'is-story': isStory }]"
      ref="slidingContainer"
      :style="`${gridDisplay}; ${borderRadiusStyle}`"
    >
      <iteration-section
        v-for="(video, index) in videos"
        :key="video.id"
        :video-index="index"
        :clickedInlineIndex="clickedInlineIndex"
        :card-height="cardHeight"
        :card-width="cardWidth"
        :number-of-videos-to-display="numberOfVideosToDisplay"
        class="video-not-selectable"
        @click-done="handleMouseDown($event)"
      ></iteration-section>
      <iteration-section
        v-if="hasYoutubeThumbnail"
        :key="+1"
        :video-index="videos.length"
        :clickedInlineIndex="clickedInlineIndex"
        :card-height="cardHeight"
        :card-width="cardWidth"
        :number-of-videos-to-display="numberOfVideosToDisplay"
        class="video-not-selectable"
        @click-done="handleMouseDown($event)"
      ></iteration-section>
    </div>

    <button
      class="arrow --next"
      v-show="
        !isStory && (numberOfVideosToDisplay < videos.length || isInPreview)
      "
      @click="nextSlide()"
    >
      <Arrow
        v-if="!hideArrows"
        :arrow-settings="formatCarouselObject.arrowSettings"
      ></Arrow>
    </button>
  </div>
</template>

<script>
import IterationSection from "./IterationSection.vue";
import SwiperVidjet from "../utils/swiper.js";
import { changeIframe } from "@/utils/utils";
import Arrow from "@/components/arrows/Arrow.vue";
import enums from "@/enums.js";
const { Position } = enums;

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "CarouselFormat",
  components: { IterationSection, Arrow },
  data() {
    return {
      containerWidth: 0,
      indexOfFirstVideoDisplayed: 0,
      gridGap: 16,
      gridDisplay: "",
      cardWidth: 250,
      cardHeight: 0,
      numberOfVideosToDisplay: 0,
      controlsSidePadding: 0,
      spaceAvailableRemaining: 0,
      touchEvent: null,
      dragX: 0,
      clickedIndex: null,
      clickedInlineIndex: null,
      isBiggerVideoPlaying: false,
      dragInfo: {
        startX: 0,
        scrollLeft: 0,
        isDown: false,
        isDragging: false,
      },
    };
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    isMobile() {
      this.calculateGrid();
    },
    cardHeight() {
      this.$emit("carousel-height", this.cardHeight);
    },

    isStory() {
      this.calculateGrid();
    },

    format() {
      this.calculateGrid();
    },

    indexOfFirstVideoDisplayed(newValue) {
      if (this.isStory) return;
      // check if video is still visible when index changes, if not, pause it by setting clickedIndex to null
      if (
        this.clickedInlineIndex > this.indexOfLastVisibleVideo ||
        this.clickedInlineIndex < this.indexOfFirstVideoDisplayed
      ) {
        this.clickedInlineIndex = null;
      }
      // dragging shouldn't loop carousel
      if (newValue < 0) {
        this.indexOfFirstVideoDisplayed = 0;
      } else if (newValue > this.maxIndexVideo) {
        this.indexOfFirstVideoDisplayed = this.maxIndexVideo;
      }
      this.positionCarousel();
    },

    clickedInlineIndex(newValue) {
      if (!this.isInPreview && newValue !== null) {
        const videoId = this.videos[this.clickedIndex].videoId;
        this.addAnalytics({ videoId: videoId });
        this.sendEvent({
          type: "videoDisplay",
        });
      }
      if (newValue !== null) {
        this.setCurrentIndex(newValue);
      }

      this.isBiggerVideoPlaying = false;
      if (newValue !== null && this.numberOfVideosToDisplay > 1) {
        this.moveVideoWhenGrowing(newValue);
      }
      // if video is not visible, scroll to it
      if (
        this.clickedInlineIndex !== null &&
        this.clickedInlineIndex > this.indexOfLastVisibleVideo
      ) {
        this.indexOfFirstVideoDisplayed = this.indexOfLastVisibleVideo;
      }
    },
    arrowsWidth() {
      this.calculateGrid();
    },
  },

  mounted() {
    this.calculateGrid();
    window.addEventListener("resize", () => {
      this.calculateGrid();
    });
    this.bindSwipingListeners();
  },

  computed: {
    ...mapGetters("playerCampaign", ["format", "isStory", "position"]),
    ...mapGetters("videos", ["videos", "thumbnail", "hasYoutubeThumbnail"]),
    ...mapGetters("analytics", ["hasFlowId"]),
    ...mapGetters("player", [
      "isTablet",
      "isMobile",
      "isInPreview",
      "hasScriptLoaded",
    ]),

    numberOfVideos() {
      // when a non portrait video is playing we still need to scroll to last video
      return this.isBiggerVideoPlaying
        ? this.videos.length + 1
        : this.videos.length;
    },

    isInlined() {
      return !this.format.isFullScreen;
    },

    maxIndexVideo() {
      return this.numberOfVideos - this.numberOfVideosToDisplay;
    },

    indexOfLastVisibleVideo() {
      return this.indexOfFirstVideoDisplayed + this.numberOfVideosToDisplay - 1;
    },

    formatCarouselObject() {
      if (this.isInlined) {
        return {
          ...this.format,
        };
      }
      return {
        ...this.format,
        ...{ hidePlayPause: false, isFullScreen: false },
      };
    },

    scrollLeftWalk() {
      let walk =
        this.indexOfFirstVideoDisplayed * (this.cardWidth + this.gridGap) +
        this.dragX;
      if (this.hideArrows) {
        walk - this.spaceAvailableRemaining;
      }
      return walk;
    },

    indexLastVideoToShow() {
      return this.indexOfFirstVideoDisplayed + this.numberOfVideosToDisplay;
    },

    isTouchable() {
      return (this.isMobile || this.isTablet) && !this.isInPreview;
    },
    arrowsWidth() {
      if (this.hideArrows) return 0;
      const bigSizeArrow = [
        "corner2",
        "corner3",
        "line2",
        "line3",
        "bold2",
        "bold3",
      ];
      if (
        bigSizeArrow.includes(this.formatCarouselObject.arrowSettings?.iconType)
      )
        return 60;
      return 35;
    },

    hideArrows() {
      return this.isMobile || this.formatCarouselObject.hideCarouselArrows;
    },

    getProperPositioning() {
      let placeItems = "center";

      //We always want to have the elements centered on mobile
      if (this.isMobile) return placeItems;

      if (this.position === Position.left) placeItems += " start";
      else if (this.position === Position.right) placeItems += " end";

      return placeItems;
    },
    borderRadiusStyle() {
      if (!Number.isInteger(this.format.cornerShape)) return "";

      let borderRadius = this.format.cornerShape.toString() + "px";
      return `border-radius: ${borderRadius};`;
    },
    wrapperStyle() {
      let marginLeft = 0;
      let marginRight = 0;
      if (this.isPreview) {
        if (this.getProperPositioning.includes("start")) {
          marginLeft = 32;
        } else if (this.getProperPositioning.includes("end")) {
          marginRight = 32;
        }
      }
      return {
        padding: `0 ${this.controlsSidePadding}px`,
        placeItems: this.getProperPositioning,
        gridTemplateColumns:
          !this.isStory &&
          (this.numberOfVideosToDisplay < this.videos.length ||
            this.isInPreview)
            ? `${this.arrowsWidth}px auto ${this.arrowsWidth}px`
            : "1fr",
        marginLeft: `${marginLeft}px`,
        marginRight: `${marginRight}px`,
      };
    },
  },

  methods: {
    // Helper methods
    ...mapMutations({
      addAnalytics: "analytics/ADD_ANALYTICS",
      setCurrentIndex: "videos/SET_CURRENT_INDEX",
    }),
    ...mapActions({
      sendEvent: "analytics/sendEvent",
    }),
    calculateGridWidth() {
      const controlWidth = this.arrowsWidth * 2 + 16 * 2;
      return this.containerWidth - controlWidth;
    },

    calculateNumberOfVideosToDisplay(gridWidth) {
      return Math.floor(gridWidth / (250 + this.gridGap));
    },

    calculateSpaceAvailable(gridWidth) {
      return gridWidth - this.gridGap * 2;
    },

    calculateCardWidth(spaceAvailable) {
      return Math.floor(spaceAvailable / this.numberOfVideosToDisplay);
    },

    calculateCarouselWidth() {
      // check if there is space to display a 250px wide video
      return (
        this.numberOfVideosToDisplay * 250 +
        (this.numberOfVideosToDisplay - 1) * this.gridGap
      );
    },

    calculateControlsSidePadding(gridWidth, carouselWidth) {
      if (this.hideArrows) {
        // get the space available to display the cropped video
        this.spaceAvailableRemaining = gridWidth - carouselWidth;
      } else {
        return (gridWidth - carouselWidth) / 2;
      }
    },

    calculateGrid() {
      this.$refs.slidingWrapper &&
        (this.containerWidth = this.$refs.slidingWrapper.offsetWidth);
      let numberOfVideos = this.videos.length;
      numberOfVideos += 1

      if (this.isStory) {
        this.gridDisplay = `grid-template-columns: repeat(${numberOfVideos}, 90px); gap: 16px;`;
        return;
      }

      const gridWidth = this.calculateGridWidth();
      this.numberOfVideosToDisplay =
        this.calculateNumberOfVideosToDisplay(gridWidth);
      // display the small thumbnails only if mobile, fullscreen and option is toggled
      if (
        this.numberOfVideosToDisplay >= numberOfVideos ||
        this.numberOfVideosToDisplay >= 3 ||
        this.isInlined ||
        this.format.carouselThumbnailAmount === 1 ||
        !this.isMobile
      ) {
        this.displayCarouselWithBigThumbnails({ gridWidth, numberOfVideos });
      } else {
        this.displayCarouselWithSmallerThumbnails({
          gridWidth,
          numberOfVideos,
        });
      }
      !this.isInPreview && this.$emit("carousel-height", this.cardHeight);
    },

    // for inline carousel and fullscreen carousel with bigger thumbnail options
    displayCarouselWithBigThumbnails({ gridWidth, numberOfVideos }) {
      let carouselWidth = this.calculateCarouselWidth();

      if (
        (this.isInlined || this.format.carouselThumbnailAmount === 1) &&
        carouselWidth < 0
      ) {
        const spaceAvailable = this.calculateSpaceAvailable(gridWidth);
        this.numberOfVideosToDisplay = 1;
        carouselWidth = spaceAvailable;
        this.cardWidth = spaceAvailable;
      } else {
        this.cardWidth = 250;
      }
      this.controlsSidePadding = this.calculateControlsSidePadding(
        gridWidth,
        carouselWidth
      );
      this.gridDisplay = `grid-template-columns: repeat(${numberOfVideos}, auto);`;
      this.cardHeight = this.cardWidth * 1.77776;
    },

    // for mobile and tablet carousel fullscreen
    displayCarouselWithSmallerThumbnails({ gridWidth, numberOfVideos }) {
      let spaceAvailable = this.calculateSpaceAvailable(gridWidth);
      this.numberOfVideosToDisplay =
        Math.floor(spaceAvailable / 3) > 150 ? 3 : 2;
      this.numberOfVideosToDisplay === 2 &&
        (spaceAvailable = spaceAvailable + this.gridGap);
      this.cardWidth = this.calculateCardWidth(spaceAvailable);
      this.controlsSidePadding = 0;
      this.gridDisplay = `grid-template-columns: repeat(${numberOfVideos}, auto)`;
      this.cardHeight = this.cardWidth * 1.77776;
    },

    handleMouseDown(index) {
      //For ixtem moto, we added another story that should perform a different action
      //We check it the index is equal to the full length
      if (index === this.videos.length) {
        return changeIframe("youtubeThumbnailClicked");
      }
      this.clickedIndex = index;
    },

    initSwipeEvent({ event }) {
      this.touchEvent = new SwiperVidjet(event, this.isTouchable);
    },

    handleTouchEvent({ event }) {
      if (!this.touchEvent) {
        return;
      }
      this.touchEvent.setEndEvent(event);
      if (!this.isInlined && this.touchEvent.getDeltaX() === 0) {
        this.toggleFullScreen(this.clickedIndex);
      } else if (this.isInlined && this.touchEvent.getDeltaX() === 0) {
        this.clickedInlineIndex = this.clickedIndex;
      } else {
        this.changeCurrentIndexToSwipedVideo();
      }
      this.positionCarousel();

      // Reset event for next touch
      this.dragX = 0;
      this.touchEvent = null;
    },

    changeCurrentIndexToSwipedVideo() {
      const swipeThresholdPercentage = this.isMobile ? 0.4 : 0.3;

      const swipeThreshold =
        swipeThresholdPercentage * (this.cardWidth + this.gridGap);
      const deltaX = this.touchEvent.getDeltaX();

      if (Math.abs(deltaX) >= swipeThreshold) {
        const adjustment =
          deltaX >= 0 ? swipeThresholdPercentage : swipeThresholdPercentage - 1;
        const numberOfPassedVideos = Math.ceil(
          Math.abs((deltaX + adjustment) / (this.cardWidth + this.gridGap))
        );

        const directionMultiplier = deltaX > 0 ? 1 : -1;
        this.indexOfFirstVideoDisplayed +=
          directionMultiplier * numberOfPassedVideos;
      }
    },

    positionCarousel() {
      const slidingContainer = this.$refs.slidingContainer;
      this.dragX = 0;
      slidingContainer.scrollTo({
        left: this.scrollLeftWalk,
        behavior: "smooth",
      });
    },

    nextSlide() {
      if (this.indexOfFirstVideoDisplayed >= this.maxIndexVideo) {
        this.indexOfFirstVideoDisplayed = 0;
      } else {
        this.indexOfFirstVideoDisplayed++;
      }
    },

    prevSlide() {
      if (this.indexOfFirstVideoDisplayed <= 0) {
        this.indexOfFirstVideoDisplayed = this.maxIndexVideo;
      } else {
        this.indexOfFirstVideoDisplayed--;
      }
    },

    bindSwipingListeners() {
      const slidingContainer = this.$refs.slidingContainer;
      const shouldSkipEventHandling = (targetClassName) => {
        const classNamesToSkip = [
          "product-image inline-product-image",
          "atc-button-container atc-button-container-mobile",
          "atc-button-container atc-button-container-mobile dragging",
          "call-to-action call-to-action-atc",
          "button-with-image is-inline",
          "add-btn btn-is-inline",
          "flex-button atc-add-button inline-atc-add-button",
          "SVGAnimatedString {baseVal: '', animVal: ''}",
        ];
        return classNamesToSkip.includes(targetClassName);
      };
      slidingContainer.addEventListener(
        "mousedown",
        (event) => {
          if (shouldSkipEventHandling(event?.target?.className)) {
            return;
          }
          if (this.isStory) {
            this.startDragging(event, slidingContainer);
          }
          this.initSwipeEvent({ event });
        },
        true
      );
      slidingContainer.addEventListener(
        "mousemove",
        (event) => {
          if (shouldSkipEventHandling(event?.target?.className)) {
            return;
          }
          if (this.isStory) {
            this.dragging(event, slidingContainer);
          } else if (
            this.touchEvent !== null &&
            this.numberOfVideosToDisplay < this.videos.length
          ) {
            // distance between 1st click and current holding position
            this.dragX = this.touchEvent.startEvent.clientX - event.clientX;
            slidingContainer.scrollLeft = this.scrollLeftWalk;
          }
        },
        true
      );
      slidingContainer.addEventListener(
        "mouseup",
        (event) => {
          if (shouldSkipEventHandling(event?.target?.className)) {
            return;
          }

          if (this.isStory) {
            this.stopDragging();
            this.toggleFullScreen(this.clickedIndex);
            return;
          } else if (this.touchEvent !== null) {
            this.handleTouchEvent({ event });
          }
        },
        true
      );
      slidingContainer.addEventListener(
        "touchstart",
        (event) => {
          if (
            event?.target?.className !== "product-image inline-product-image" &&
            event?.target?.className !==
              "atc-button-container atc-button-container-mobile" &&
            event?.target?.className !==
              "atc-button-container atc-button-container-mobile dragging" &&
            event?.target?.className !== "call-to-action call-to-action-atc" &&
            event?.target?.className !== "add-btn btn-is-inline" &&
            event?.target?.className !== "button-with-image is-inline" &&
            event?.target?.className !==
              "flex-button atc-add-button inline-atc-add-button"
          ) {
            if (this.isStory) {
              this.startDragging(event, slidingContainer);
            }
            this.initSwipeEvent({ event });
          }
        },
        true
      );
      slidingContainer.addEventListener(
        "touchmove",
        (event) => {
          if (shouldSkipEventHandling(event?.target?.className)) {
            return;
          }
          // compare vertical and horizontal distance to decide if we want to scroll or swipe
          // if the scroll is horizontal, prevent scrolling down the page
          if (
            Math.abs(
              this.touchEvent.startEvent.changedTouches[0].screenY -
                event.changedTouches[0].screenY
            ) <
            Math.abs(
              this.touchEvent.startEvent.changedTouches[0].screenX -
                event.changedTouches[0].screenX
            )
          ) {
            event.preventDefault();
          } else {
            return;
          }
          if (this.isStory) {
            this.dragging(event, slidingContainer);
          } else if (this.touchEvent !== null) {
            // distance between 1st touch and current holding position
            this.dragInfo.isDragging = true;
            this.dragX =
              this.touchEvent.startEvent.changedTouches[0].screenX -
              event.changedTouches[0].screenX;

            slidingContainer.scrollLeft = this.scrollLeftWalk;
          }
        },
        true
      );
      slidingContainer.addEventListener(
        "touchend",
        (event) => {
          if (shouldSkipEventHandling(event?.target?.className)) {
            return;
          }
          if (this.isStory) {
            this.stopDragging();
            this.toggleFullScreen(this.clickedIndex);
            return;
          } else if (this.touchEvent !== null) {
            this.handleTouchEvent({ event });
            this.dragInfo.isDragging = false;
          }
        },
        true
      );
    },

    stopDragging() {
      this.dragInfo.isDown = false;
      setTimeout(() => {
        this.dragInfo.isDragging = false;
      }, 100);
    },

    dragging(e, slidingContainer) {
      if (!this.dragInfo.isDown) return;
      let pageX = e.pageX ? e.pageX : e.touches[0].pageX;
      this.dragInfo.isDragging = true;
      const x = pageX - slidingContainer.offsetLeft;
      const walk = x - this.dragInfo.startX;
      slidingContainer.scrollLeft = this.dragInfo.scrollLeft - walk;
    },

    startDragging(e, slidingContainer) {
      this.dragInfo.isDown = true;
      let pageX = e.pageX ? e.pageX : e.touches[0].pageX;
      this.dragInfo.startX = pageX - slidingContainer.offsetLeft;
      this.dragInfo.scrollLeft = slidingContainer.scrollLeft;
    },

    moveVideoWhenGrowing(index) {
      const isVideoLarge =
        this.videos[index].width / this.videos[index].height > 0.99;

      const canVideoGrowLarger =
        this.numberOfVideosToDisplay -
          (index - this.indexOfFirstVideoDisplayed) <
        2;
      isVideoLarge && (this.isBiggerVideoPlaying = true);
      if (isVideoLarge && canVideoGrowLarger) {
        this.indexOfFirstVideoDisplayed = this.clickedIndex;
      }
    },
    toggleFullScreen(videoIndex) {
      if (videoIndex == null) return;
      if (this.dragX === 0 && !this.dragInfo.isDragging) {
        this.clickedInlineIndex = videoIndex;
        if (!this.isStory && !this.isInlined) {
          this.$emit("open-full-screen", videoIndex);
        } else {
          const intervalId = setInterval(() => {
            // no script in development mode
            const regex = /\b(192\.\d{1,3}\.\d{1,3}\.\d{1,3}|localhost)\b/;
            if (this.hasScriptLoaded || regex.test(window.location.origin)) {
              this.$emit("open-full-screen", videoIndex);
              clearInterval(intervalId);
            }
          }, 1000);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sliding-wrapper {
  height: 100%;
  display: grid;
  max-width: 100%;
  gap: 16px;
  .arrow {
    z-index: $index-icon-position;
    background: transparent;
    cursor: pointer;
    &.--prev {
      transform: scale(1.2) rotate(180deg);
    }
    &.--next {
      transform: scale(1.2);
    }
  }
}

.sliding-wrapper.hideArrows {
  gap: 0;
}

.vidjet-carousel-items {
  display: grid;
  place-items: center;
  gap: 16px;
  overflow: hidden;
  max-width: 100%;
  cursor: pointer;
  border-radius: 10px;
}
.vidjet-carousel-items.is-story {
  cursor: auto !important;
  align-items: start;
}
.video-not-selectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>
