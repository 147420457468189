<template>
  <!-- TODO: adding this component was always a mistake, to be removed -->
  <div>
    <div
      :style="borderRadiusStyle"
      class="iteration-section-wrapper"
      :class="[{ 'is-story': isStory }]"
      @mousedown="$emit('click-done', videoIndex)"
      @touchstart="$emit('click-done', videoIndex)"
    >
      <div
        v-if="isStory"
        :class="[
          'solid-border',
          { animate: clickedInlineIndex === videoIndex },
        ]"
        :style="`border: 3px solid ${format.borderColor}`"
      ></div>
      <div
        v-if="isStory"
        :class="[
          'dotted-border',
          { animate: clickedInlineIndex === videoIndex },
        ]"
        :style="`border: 3px dashed ${format.borderColor}`"
      ></div>
      <VideoPlayer
        class="vidjet-carousel-item"
        :video-index="videoIndex"
        :clicked-index="isInlined ? clickedInlineIndex : 0"
        :style="`${makeNonPortraitVideosWider(videoIndex)}; ${
          !isInlined && 'pointer-events: none; touch-action: none;'
        }`"
      />
    </div>
    <div class="story-title-wrapper">
      <h3
        v-if="isStory && textOptions.text"
        class="story-title"
        :style="[
          textOptionsStyle,
          shouldLowerFontSize,
          { 'font-size': `${textOptions.size}px` },
        ]"
      >
        {{ decodeText(textOptions.text) }}
      </h3>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
import { decodeText } from "@/utils/utils.js";
import { mapGetters } from "vuex";
export default {
  name: "IterationSection",
  components: { VideoPlayer },
  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
    numberOfVideosToDisplay: {
      type: Number,
    },
    cardWidth: {
      type: Number,
    },
    cardHeight: {
      type: Number,
    },
    clickedInlineIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      decodeText,
    };
  },

  computed: {
    ...mapGetters("playerCampaign", ["format", "isStory"]),
    ...mapGetters("videos", ["videos", "getVideoByIndex"]),

    isInlined() {
      return !this.format?.isFullScreen;
    },
    textOptions() {
      if (this.videoIndex === this.videos.length) {
        return {
          color: "black",
          size: 12,
          isBold: false,
          text: "La vidéo complète"
        }
      }
      
      return this.getVideoByIndex(this.videoIndex)?.textOptions ?? {};
    },
    textOptionsStyle() {
      if (Object.keys(this.textOptions ?? {}).length > 0) {
        return {
          color: this.textOptions.color,
          fontWeight: this.textOptions.isBold ? "bold" : "initial",
          fontStyle: this.textOptions.isItalic ? "italic" : "normal",
          textDecoration: this.textOptions.isUnderlined ? "underline" : "none",
        };
      } else {
        return {};
      }
    },
    shouldLowerFontSize() {
      if (
        this.$route.params.siteId === "90d4f9ae-5daa-448a-b9f9-3c2c4a1fa2b5"
      ) {
        return { "font-size": "10px" };
      }

      return {};
    },
    borderRadiusStyle() {
      if (!Number.isInteger(this.format.cornerShape)) return {};

      let borderRadius = this.format.cornerShape.toString() + "px";
      return { borderRadius: borderRadius };
    },
  },
  methods: {
    makeNonPortraitVideosWider(videoIndex) {
      if (this.isStory) return;
      return `height: ${this.cardHeight}px; width: ${
        videoIndex === this.clickedInlineIndex &&
        this.videos[videoIndex].width / this.videos[videoIndex].height > 0.99 &&
        this.numberOfVideosToDisplay > 1
          ? this.cardHeight
          : this.cardWidth
      }px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.iteration-section-wrapper {
  cursor: pointer;
  transition: width 0.5s ease-in-out;
}

.is-story .vidjet-carousel-item {
  overflow: hidden;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  cursor: pointer;
}

.iteration-section-wrapper {
  width: 100%;
  border-radius: 10px;
}
.iteration-section-wrapper.is-story {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iteration-section.is-story {
  clip-path: circle(40%);
  -webkit-clip-path: circle(40%);
}

.story-title-wrapper {
  width: 100%;
}
.story-title {
  text-align: center;
  width: 90%;
  margin: 4px auto 0;
  line-height: 15px;
}

.solid-border,
.dotted-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-sizing: border-box;
}

.inner {
  box-sizing: border-box;
  width: 90%; /* Réduit pour tenir compte de l'épaisseur de la bordure */
  height: 90%; /* Réduit pour tenir compte de l'épaisseur de la bordure */
  border-radius: 50%;
}

.animate.dotted-border {
  animation: 2s linear 0s infinite normal none running rotateAnimation;
}

.animate.solid-border {
  animation: borderAnimation 4s linear infinite;
}

@keyframes borderAnimation {
  0%,
  49.9% {
    opacity: 0; /* Solid caché */
  }
  50%,
  100% {
    opacity: 1; /* Solid visible */
  }
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
